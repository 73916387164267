import { XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserPreferredColumResponse } from 'src/api';
import useUpdatePreferredColumns from 'src/api/hooks/mutations/userProfile/useUpdatePreferredColumns';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import Button from 'src/components/Button';
import AutoPopup from 'src/components/common/AutoPopup';
import Loader from 'src/components/utils/Loader';
import useClosePopup from 'src/hooks/popups/useClosePopup';
import useEvent from 'src/hooks/useEvent';
import { ORDERS_TABLE_COLUMNS } from '../ServiceTable';
import OrderColumnConfigurationList from './components/OrderColumnConfigurationList/OrderColumnConfigurationList';

export type OrderColumnsConfigurationPopupProps = {
  //
};

export const ORDER_COLUMNS_CONFIGURATION_DIALOG_ID = 'OrderColumnsConfigurationPopupId';

const getDefaultColumns = (configuredColumns: UserPreferredColumResponse[]): UserPreferredColumResponse[] => {
  const notConfiguredColumns = ORDERS_TABLE_COLUMNS.filter(
    (column) => !configuredColumns.some((configuredColumn) => column.id === configuredColumn.id),
  )
    .map((i) => ({ id: i.id as string, visible: false }))
    .filter(Boolean);

  return [...configuredColumns, ...notConfiguredColumns];
};

const OrderColumnsConfigurationPopup: FC<OrderColumnsConfigurationPopupProps> = () => {
  const { data: currentUser, isLoading } = useCurrentUser();
  const { data: currentTenantConfig, isLoading: isLoadingTenant } = useCurrentTenantConfig();
  const configuredColumns = useMemo(
    () =>
      currentUser?.profile?.preferredColumns ??
      currentTenantConfig?.orderTable?.defaultColumns?.map((i) => ({ id: i, visible: true })) ??
      [],
    [currentUser, currentTenantConfig],
  );
  const [items, setItems] = useState<UserPreferredColumResponse[]>(getDefaultColumns(configuredColumns));

  const handleToggleColumnVisibility = useCallback((id: string) => {
    setItems((prev) => prev.map((i) => (i.id === id ? { ...i, visible: !i.visible } : i)));
  }, []);

  const updatePreferredColumns = useUpdatePreferredColumns();
  const closePopup = useClosePopup(ORDER_COLUMNS_CONFIGURATION_DIALOG_ID);

  const handleSave = useEvent(() => {
    updatePreferredColumns.mutate(items);
    closePopup();
  });

  useEffect(() => {
    setItems(getDefaultColumns(configuredColumns));
  }, [configuredColumns]);

  if (isLoading || isLoadingTenant) return <Loader />;

  return (
    <AutoPopup
      id={ORDER_COLUMNS_CONFIGURATION_DIALOG_ID}
      title={<FormattedMessage id='app.columns_configuration_popup.title' />}
    >
      <OrderColumnConfigurationList
        items={items}
        onOrderChange={setItems}
        onToggleColumnVisibility={handleToggleColumnVisibility}
      />
      <div className='mt-[2.875rem] flex justify-end gap-x-6 sm:mt-4'>
        <Button size='normal' type='button' variant='secondary' onClick={closePopup}>
          <XMarkIcon className='h-8 w-8 mr-1 text-gray' aria-hidden='true' />
          <FormattedMessage id='app.buttons.close' defaultMessage='Zavřít' />
        </Button>
        <Button size='normal' type='submit' onClick={handleSave}>
          <FormattedMessage id='app.buttons.save' defaultMessage='Uložit' />
        </Button>
      </div>
    </AutoPopup>
  );
};

OrderColumnsConfigurationPopup.displayName = 'OrderColumnsConfigurationPopup';

export default OrderColumnsConfigurationPopup;
