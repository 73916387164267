import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC } from 'react';
import { OrderListItemResponse, UserPreferredColumResponse } from 'src/api';
import { ORDERS_TABLE_COLUMNS } from 'src/pages/HomePage/ServiceTable';
import { ExtendedColumnDef } from 'src/types/table';
import OrderColumnConfigurationRow from '../OrderColumnConfigurationRow';

const idToCollumnMap = ORDERS_TABLE_COLUMNS.reduce<Record<string, ExtendedColumnDef<OrderListItemResponse>>>(
  (acc, column) => {
    const id = column.id as string;

    if (!id) return acc;

    return {
      ...acc,
      [id]: column,
    };
  },
  {} as Record<string, ExtendedColumnDef<OrderListItemResponse>>,
);

export type OrderColumnConfigurationListProps = {
  items: UserPreferredColumResponse[];
  onOrderChange: (columns: UserPreferredColumResponse[]) => void;
  onToggleColumnVisibility: (id: string) => void;
};
const OrderColumnConfigurationList: FC<OrderColumnConfigurationListProps> = ({
  onOrderChange,
  items,
  onToggleColumnVisibility,
}) => {
  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (!active || !over) return;
    if (active.id === over.id) return;

    const oldIndex = items.findIndex((i) => i.id === (active.id as string));
    const newIndex = items.findIndex((i) => i.id === (over.id as string));

    const nextItems = arrayMove(items, oldIndex, newIndex);
    onOrderChange(nextItems);
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <OrderColumnConfigurationRow
            key={item.id}
            id={item.id}
            visible={item.visible}
            column={idToCollumnMap[item.id]}
            onClick={() => onToggleColumnVisibility(item.id)}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};
export default OrderColumnConfigurationList;
